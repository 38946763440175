import angular from 'angular'
import _omit from 'lodash/omit'
import _isEqual from 'lodash/isEqual'
import _assign from 'lodash/assign'

import { fetchFolders } from 'AppCore/folder/api'

export function ptFolderRoute(
  $stateProvider,
  filterParamsProvider,
  recordViewOptionsProvider,
  recordPaginationOptionsProvider
) {
  'ngInject'
  let params = mapParams()

  let folderResolves = {
    hasManyFolders: resolveHasManyFolders,
    hasPartnerships: resolveHasPartnerships,
    folder: resolveFolder,
    treeSegment: resolveTreeSegment
  }

  $stateProvider
    .state('folders', {
      parent: 'account',
      url: '/folders?' + params,
      views: {
        'content@layoutFull': {
          component: 'ptFolder'
        }
      },
      resolve: folderResolves,
      onEnter: onFoldersEnter
    })
    .state('bucket', {
      parent: 'account',
      url: '/collection?' + params,
      views: {
        'content@layoutFull': {
          component: 'ptFolder'
        }
      },
      params: {
        bucket: true,
        view: 'record'
      },
      resolve: folderResolves,
      onEnter: onFoldersEnter
    })
    .state('folder', {
      parent: 'account',
      url: '/folders/{folderId:(?:[a-fA-F0-9]{8}(?:-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12})}?' + params,
      views: {
        'content@layoutFull': {
          component: 'ptFolder'
        }
      },
      resolve: folderResolves,
      onEnter: onFoldersEnter
    })
  function resolveHasManyFolders(context, $q) {
    'ngInject'

    // Retrieve and parse 'folderSort' from local storage
    const folderSort = localStorage.getItem('folderSort')
    let sortName = ''

    if (folderSort) {
      try {
        const parsedSort = JSON.parse(folderSort)
        if (parsedSort[0] && parsedSort[0].key === 'name') {
          sortName = parsedSort[0].direction === 'ASC' ? 'name' : '-name'
        }
      } catch (error) {
        console.error('Failed to parse folderSort from local storage:', error)
      }
    }

    // Wrap in an Angular promise to cause a digest cycle, as fetchFolders is vanilla JavaScript
    return $q
      .resolve(
        fetchFolders({
          accountId: context.get('account').id,
          query: {
            cursor: 250,
            limit: 1,
            ...(sortName && { sort: sortName }) // Add sort parameter if available
          }
        })
      )
      .then((response) => {
        return response.cursor.hasMore
      })
      .catch(() => {})
  }

  function resolveHasPartnerships(context, $q) {
    'ngInject'

    // Retrieve and parse 'folderSort' from local storage
    const folderSort = localStorage.getItem('folderSort')
    let sortName = ''

    if (folderSort) {
      try {
        const parsedSort = JSON.parse(folderSort)
        if (parsedSort[0] && parsedSort[0].key === 'name') {
          sortName = parsedSort[0].direction === 'ASC' ? 'name' : '-name'
        }
      } catch (error) {
        console.error('Failed to parse folderSort from local storage:', error)
      }
    }

    // Wrap in an Angular promise to cause a digest cycle, as fetchFolders is vanilla JavaScript
    return $q
      .resolve(
        fetchFolders({
          accountId: context.get('account').id,
          query: {
            cursor: 250,
            limit: 1,
            ...(sortName && { sort: sortName }) // Add sort parameter if available
          }
        })
      )
      .then((response) => {
        return response.cursor.hasPartnerships
      })
      .catch(() => {})
  }

  function resolveFolder(
    account,
    filters,
    foldersResolves,
    ErrorsDisplay,
    $stateParams,
    filteringContext,
    recordsViewMode,
    recordsPagination
  ) {
    'ngInject'

    // We require account and filters DI to ensure they are resolved before this route
    filteringContext.setInitialFilter($stateParams)
    recordsViewMode.setInitialParams($stateParams)
    recordsPagination.setInitialParams($stateParams)

    return foldersResolves.folder(account, $stateParams.folderId || account.folder.id).catch(function (rejection) {
      return ErrorsDisplay.catch(rejection.$response.data.error)
    })
  }

  function resolveTreeSegment(account, filters, $stateParams, foldersResolves, hasManyFolders, hasPartnerships?) {
    'ngInject'

    const params = !hasManyFolders || hasPartnerships ? { include: 'descendants,count' } : null

    // We require account and filters DI to ensure they are resolved before this route

    return foldersResolves.treeSegment(account, $stateParams.folderId || account.folder.id, params)
  }

  function onFoldersEnter($state, $transition$, gettextCatalog, page, folder) {
    'ngInject'

    // For some reason using $transition$.params('bucket') errors...
    var params = angular.copy($transition$.params())

    if (params.bucket) {
      page.setTitle(gettextCatalog.getString('Collection'))
    } else {
      if (folder.parentId) {
        //page.setTitle(folder.name)
        page.setTitle(gettextCatalog.getString('Folder'))
      } else {
        /// Page Title
        page.setTitle(gettextCatalog.getString('Folders'))
      }
    }
  }

  function mapParams() {
    const filterParams = filterParamsProvider
      .params()
      .concat(recordViewOptionsProvider.params())
      .concat(recordPaginationOptionsProvider.params())

    return filterParams
      .map(function (param) {
        return '{' + param + ':any}'
      })
      .join('&')
  }
}
