import * as React from 'react'
import * as moment from 'moment-timezone'
import { View, Text } from 'reactxp'
import { Task } from '../models/index'
import { spacing, colors } from '../../styles'
import { Button } from '../../components/index'
import { fetchLog } from '../../logs/api/fetch-log-resource'

interface Props {
  task: Task
  editable: boolean
  onPressRecordName: () => void
  onPressLogAssign: () => void
  onPressInspection: () => void
}

export const TaskDetailRecord = ({ task, onPressRecordName, onPressLogAssign, onPressInspection, editable }: Props) => {
  const [log, setLog] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (task.log && (!log || task.log.id !== (log && log.id))) {
      setIsLoading(true)

      fetchLog({
        accountId: task.account.id,
        recordId: task.record.id,
        logId: task.log.id,
        query: { include: 'state' }
      })
        .then((response) => {
          setLog(response)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else if (!task.log) {
      setLog(null)
    }
  }, [task])

  return (
    <>
      {isLoading && <Text>Loading...</Text>}
      {!isLoading && (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flexGrow: 1 }}>
            <Text style={{ color: colors.secondary, alignSelf: 'flex-start' }} onPress={() => onPressRecordName()}>
              {task.record.name}
            </Text>
            {log && (
              <View style={{ marginTop: 5, flexDirection: 'row' }}>
                <View style={{ flexShrink: 1, marginRight: spacing.half }}>
                  <Text style={{ color: colors.neutralTintTwo }}>INSPECTION:</Text>
                </View>
                <View style={{ flexGrow: 1 }}>
                  <Text style={{ color: `rgb(${log.state.colour})` }} onPress={() => onPressInspection()}>
                    {log.state.name} <br />
                    {moment(log.createdAt).format('Do MMM YYYY H:mm a')}
                  </Text>
                </View>
              </View>
            )}
            {!log && <Text style={{ marginTop: 5 }}>This task is not assigned to a specific inspection</Text>}
          </View>
          <View>
            {editable && (
              <Button
                label={task.log ? 'Reassign' : 'Assign'}
                theme={{
                  labelColor: 'neutral',
                  backgroundColor: 'white',
                  borderColor: 'neutralTintFive'
                }}
                onPress={() => onPressLogAssign()}
              />
            )}
          </View>
        </View>
      )}
    </>
  )
}
