import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'

export function Controller(context, TasksData, permissionsCheck, $uibModal, $rootScope) {
  'ngInject'

  this.$onInit = () => {
    this.files = this.field.files || []
    this.accountId = context.get('account').id
    this.tasks = []
    this.showTasks = TasksData.flag(this.account) === 'active'

    this.account = context.get('account')

    this.showTasks = TasksData.flag(this.account) === 'active' && this.mode !== 'edit'

    const isViewOnly = permissionsCheck.is('viewOnly', this.account)
    const canAddTasks = permissionsCheck.can('add', 'tasks', this.account)

    this.canCreateTasks = !isViewOnly && canAddTasks
  }

  this.$onChanges = (changes) => {
    if (changes.resolve) {
      this.checklistName = _cloneDeep(this.resolve.checklistName)
      this.checklistOptions = _cloneDeep(this.resolve.checklistOptions)
      this.section = _cloneDeep(this.resolve.section)

      // Fetch the field from the new section object to keep the section header count working
      this.field = _find(this.section.fields.data, { id: this.resolve.field.id })

      // Override this setting as we are already in it
      this.checklistOptions.editField = false
    }
  }

  this.save = () => {
    $rootScope.$broadcast('fieldTasksUpdated', this.tasks)
    this.dismiss({ $value: this.field })
  }

  this.setFiles = (event) => {
    this.field.uploads = event.model
    this.field.files = event.files
  }

  this.setUploading = (event) => {
    this.uploading = event.uploading
  }

  this.openCreateTaskModal = () => {
    $uibModal
      .open({
        component: 'ptTaskCreate',
        size: 'l',
        backdrop: 'static',
        resolve: {
          recordId: () => this.resolve.record,
          isLogTask: () => true,
          title: () => this.field.label
        }
      })
      .result.catch((task) => {
        if (task && task.id) {
          TasksData.getTaskById(task.id).then((newTask) => {
            this.tasks = [...this.tasks, ...[newTask]]
          })
        }
      })
  }
}
