export const editLogRoutes = [
  'account.records.log',
  'activities.records.log',
  'folder.records.log',
  'bucket.records.log'
]

export const ptLogsEditRoute = ($stateProvider) => {
  'ngInject'

  let editLogModal

  const editLogState = (state) => {
    $stateProvider.state(state, {
      url: '/log/{logId}',

      views: {
        'utility@layoutBase': {
          template: ' '
        }
      },
      resolve: {
        permission: addLogPermission,
        resolvedRecords: resolveRecords,
        batch: isBatch
      },
      onEnter: onEditLogEnter,
      onExit: onEditLogExit
    })
  }

  const isBatch = ($stateParams) => {
    if ($stateParams.recordIds) {
      return true
    } else {
      return false
    }
  }

  const resolveRecords = ($stateParams, LogsData, ErrorsDisplay) => {
    'ngInject'

    const includes = [
      'record',
      'user',
      'state',
      'uploads',
      'source',
      'checklist_completions.sections.fields.response_uploads',
      'checklist_completions.checklist'
    ]

    return LogsData.getLog($stateParams.accountId, $stateParams.recordId, $stateParams.logId, includes).catch(() =>
      ErrorsDisplay.catch('select_records_for_log')
    )
  }

  const addLogPermission = (account, member, permissionsCheck, ErrorsResolve) => {
    'ngInject'

    return permissionsCheck.canPromise('add', 'logs', account).catch(ErrorsResolve.caught)
  }

  const onEditLogEnter = ($uibModal, $timeout, $state, resolvedRecords, batch, page) => {
    'ngInject'

    page.setTitle('Edit an Inspection')

    editLogModal = $uibModal.open({
      component: 'ptLogsEdit',
      backdrop: 'static',
      windowClass: 'test_new-log',
      size: 'xl',
      resolve: {
        records: () => resolvedRecords,
        isBatch: () => batch
      }
    })

    editLogModal.result.catch((reason) => {
      $timeout(() => {
        $state.go('^')
      }, 500)
    })
  }

  const onEditLogExit = (page) => {
    'ngInject'
    page.resetTitle()
    editLogModal.close()
  }

  editLogRoutes.forEach((route) => {
    editLogState(route)
  })
}
