export function Controller($scope, recordsData, gettextCatalog, context) {
  'ngInject'

  let vm = this

  vm.control = {}

  vm.field = {
    required: false,
    label: 'Shared'
  }

  /////////////

  vm.$onInit = function () {
    vm.account = context.get('account')
    vm.companyName = vm.account.subscription.company_name
    vm.partnerships = $scope.vm.record.partnerships
    vm.partnershipNames = vm.partnerships.map((partnership) => partnership.name)
  }
}
