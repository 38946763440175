import _assign from 'lodash/assign'

export function Controller($uibModal, $state) {
  'ngInject'

  this.$onInit = () => {
    this.showInfo = false
    this.fullView = $state.current.name.includes('activity')
  }

  this.$onChanges = () => {}

  this.openFieldEdit = () => {
    $uibModal
      .open({
        component: 'ptChecklistFieldEdit',
        size: 'xl',
        resolve: {
          checklistName: () => this.checklistName,
          checklistOptions: () => this.checklistOptions,
          section: () => this.section,
          field: () => this.field,
          record: () => this.record.id
        }
      })
      .result.catch((field) => {
        _assign(this.field, field)

        this.onValueChange()
      })
  }

  this.showInfoToggle = () => {
    this.showInfo = !this.showInfo
  }

  this.valueChange = () => {
    this.onValueChange()
  }
}
