export function Controller(
  $timeout,
  $state,
  context,
  foldersData,
  gettextCatalog,
  TutorialsFlags,
  ErrorsModal,
  SubscriptionsData
) {
  'ngInject'

  let vm = this
  let s = {
    /// Input label
    folderName: gettextCatalog.getString('Folder name'),
    /// Input placeholder
    folderNamePlaceholder: gettextCatalog.getString('Enter folder name...'),
    /// Input label
    description: gettextCatalog.getString('Description')
  }

  vm.control = {
    closeModal: closeModal,
    save: save,
    topLevel: topLevel
  }

  vm.$onInit = function () {
    vm.$transitions = vm.resolve.$transitions

    vm.memberPickerReady = false
    vm.tutorial = null
    vm.saving = false
    vm.error = null
    vm.isTopLevel = !vm.$transitions.params().folderId && !vm.$transitions.params().selectedFolder

    vm.account = context.get('account')

    if (!vm.isTopLevel) {
      foldersData
        .getFolder(vm.account, vm.$transitions.params().folderId || vm.$transitions.params().selectedFolder, {
          include: 'ancestors,user'
        })
        .then((folder) => {
          vm.sharedFolder = folder.partner
        })

      SubscriptionsData.fetchSubscription(context.get('account').subscription.id).then((subscription) => {
        vm.isHost = subscription.host_partnerships && subscription.host_partnerships > 0
      })
    }

    vm.model = {
      name: '',
      description: '',
      parentId:
        vm.$transitions.params().folderId ||
        vm.$transitions.params().selectedFolder ||
        context.get('account').folder.id,
      member_ids: null
    }

    vm.formFields = [
      {
        key: 'name',
        type: 'input',
        ngModelElAttrs: {
          'data-cy': 'new-folder-name'
        },
        ngModelAttrs: {
          ptFocus: {
            attribute: 'pt-focus'
          }
        },
        templateOptions: {
          label: s.folderName,
          placeholder: s.folderNamePlaceholder,
          required: true,
          ptFocus: true
        }
      },
      {
        key: 'description',
        ngModelElAttrs: {
          'data-cy': 'new-folder-desc'
        },
        type: 'textareaExpanding',
        templateOptions: {
          label: s.description
        }
      }
    ]

    getMemberIds()

    TutorialsFlags.getUserFlag('created_folder').then(function (flag) {
      vm.tutorial = flag
    })
  }

  function getMemberIds() {
    foldersData
      .getFolder(context.get('account'), vm.model.parentId, { filter: false, include: 'members' })
      .then(function (folder) {
        vm.model.member_ids = folder.members.map((member) => member.id)
      })
      .catch((response) => {
        ErrorsModal.openModal(response.error)
      })
  }

  function save() {
    if (vm.form.$valid) {
      vm.saving = true
      vm.error = null

      foldersData
        .create(context.get('account'), vm.model)
        .then(saveSuccess, saveError)
        .catch((response) => {
          ErrorsModal.openModal(response.error)
        })
    }
  }

  function saveSuccess() {
    vm.dismiss({ value: 'saved' })
  }

  function saveError(rejection) {
    vm.saving = false
    ErrorsModal.openModal(rejection.error)
  }

  function closeModal() {
    vm.dismiss({ value: 'cancel' })
  }

  function topLevel() {
    closeModal()
    $timeout(function () {
      $state.go('account.new-folder')
    })
  }
}
